const config = {
  apiKey: "AIzaSyCfi0z8ZNGuLxH1eRjdQkdUNJdwhafy83s",
  authDomain: "housecon-sac.firebaseapp.com",
  projectId: "housecon-sac",
  storageBucket: "housecon-sac.appspot.com",
  messagingSenderId: "912754927205",
  appId: "1:912754927205:web:652b58afd8ef281ced888c"
  // measurementId: "G-CGRHDM4K1X"
}
export default config;
